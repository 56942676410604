<template>
    <router-link v-bind="$attrs" class="lawyer-item">
        <hover-line>
            <v-img :src="image" :aspect-ratio="1 / 1" class="w-100 grey lighten-4" />
            <div class="py-16px py-md-24px text-center">
                <u-txt-default class="txt--sm grey--text text--darken-3 line-height-1">
                    <slot name="txt" />
                </u-txt-default>
                <div class="lawyer-item__tit pt-8px pt-md-12px mt-8px mt-md-12px">
                    <u-tit-default class="tit--sm grey--text text--darken-3 line-height-1">
                        <slot />
                    </u-tit-default>
                </div>
            </div>
        </hover-line>
    </router-link>
</template>

<script>
import HoverLine from "@/components/publish/styles/hover/hover-line.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {
        image: { type: String, default: "" },
    },
    components: {
        HoverLine,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
};
</script>

<style lang="scss" scoped>
.lawyer-item {
    display: block;
    width: 100%;
    &__tit {
        border-top: 1px solid var(--border-color);
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            top: -1px;
            left: 0;
            background-color: var(--v-primary-lighten3);
            transform: scaleX(0);
            transition: 0.25s ease-out;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    // .lawyer-item:hover {
    //     .lawyer-item__tit {
    //         &::after {
    //             transform: scaleX(1);
    //         }
    //     }
    // }
}
@media (min-width: 1200px) {
}
</style>