var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "성범죄 보안처분"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("S")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("ecurity disposition")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "overflow-hidden"
  }, [_c('v-img', {
    staticClass: "min-h-md-240px mb-12px mb-md-24px",
    attrs: {
      "data-aos": "zoom-out",
      "src": "/images/sub/disposition/disposition-img.jpg",
      "aspect-ratio": 1200 / 240
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column justify-center align-center text-center pa-20px pa-md-30px pa-lg-48px"
  }, [_c('icon-double-quotes', {
    staticClass: "primary--text text--lighten-3 mb-8px mb-md-16px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400",
      "data-aos-offset": "0"
    }
  }), _c('u-tit-default', {
    staticClass: "white--text line-height-1",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "600",
      "data-aos-offset": "0"
    }
  }, [_vm._v("성범죄 보안처분이란?")]), _c('u-txt-default', {
    staticClass: "grey--text text--lighten-3 line-height-17 mt-4px mt-md-10px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "800",
      "data-aos-offset": "0"
    }
  }, [_vm._v(" 유죄가 확정되어 벌금형, 징역형 등의 처벌을 받는 것 외에 부수적으로 받게 되는 법률상 불이익을 말합니다. ")])], 1)])], 1), _c('div', [_c('line-row', {
    attrs: {
      "topLine": ""
    },
    scopedSlots: _vm._u([{
      key: "titAdd",
      fn: function () {
        return [_vm._v("01")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("신상정보등록")];
      },
      proxy: true
    }])
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 성범죄로 벌금 이상의 유죄판결이나 약식명령이 확정된 경우에 부수되는 보안처분입니다. "), _c('br'), _vm._v(" (아동청소년이용음란물소지죄, 성적목적공공장소침입죄, 카메등이용촬영죄, 통신매체이용 음란죄 제외). 대부분의 성범죄에서 벌금만 내도 신상정보등록이 부과되므로 다른 기타범죄와 성범죄가 차별되는 부분이기도 합니다. ")]), _c('card-secondary', {
    staticClass: "mt-12px mt-md-24px"
  }, [_c('div', {
    staticClass: "pa-16px px-md-36px py-md-24px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, _vm._l(_vm.prison, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 200 + index * 100,
        "cols": "6",
        "lg": "3"
      }
    }, [_c('v-responsive', {
      staticClass: "image-card w-100",
      staticStyle: {
        "background-image": "url(/images/icon/rhomb.svg)"
      },
      attrs: {
        "aspect-ratio": 1 / 1,
        "content-class": "pa-16px d-flex flex-column align-center justify-center text-center"
      }
    }, [_c('u-txt-default', {
      staticClass: "txt--lg font-weight-bold line-height-1 txt--dark"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "grey--text line-height-13 mt-4px mt-md-8px"
    }, [_vm._v(_vm._s(item.txt))])], 1)], 1);
  }), 1)], 1)]), _c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 등록대상자는 기본신상정보를 유죄판결 확정일로부터 30일 이내에 주소지 또는 거주지를 관할하는 경찰관서의 장에게 직접 방문하여 신상정보 제출해야 합니다(다만, 교정시설에 수용된 경우에는 수용시설의 장에게 제출) 또한 신상정보를 제출한 그 다음 해부터 매년 12월 31일까지 주소지 관할 경찰관서에 출석하여 사진 촬영을 해야 합니다. ")]), _c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 신상정보에 변경이 있다면 변경 사유가 발생한 날로부터 20일 이내에 변경 내용을 관할 경찰관서의 장에게 제출해야 하고 해외출입국 시 6개월 이상 국외에 체류하기 위하여 출국하는 경우 관할 경찰관서의 장에게 출국 신고, 입국일로부터 14일 이내 관할 경찰관서의 장에게 입국 신고를 해야 합니다. ")])], 1), _c('line-row', {
    scopedSlots: _vm._u([{
      key: "titAdd",
      fn: function () {
        return [_vm._v("02")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("신상정보공개, 고지")];
      },
      proxy: true
    }])
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 성범죄자 신상정보 공개, 고지 제도는 등록대상 성범죄 사건의 판결과 동시에 공개 및 고지 명령을 선고하고, 법무부에서 신상정보를 등록한 후 공개 및 고지에 필요한 정보를 여성가족부로 전송, 여성가족부가 공개와 고지명령을 집행합니다. ")]), _c('div', {
    staticClass: "pt-12px pt-md-24px"
  }, [_c('v-row', _vm._l(_vm.notice, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 200 + index * 100,
        "cols": "12",
        "md": "6"
      }
    }, [_c('card-primary', {
      staticClass: "h-100",
      attrs: {
        "color": "grey lighten-5"
      }
    }, [_c('div', {
      staticClass: "pa-16px pa-md-24px"
    }, [_c('u-txt-default', {
      staticClass: "txt--lg font-weight-bold txt--dark"
    }, [_vm._v(_vm._s(item.title))]), _c('u-txt-default', {
      staticClass: "txt--sm line-height-15 mt-12px mt-md-24px"
    }, [_vm._v(" " + _vm._s(item.txt) + " ")])], 1)])], 1);
  }), 1)], 1)]), _c('line-row', {
    scopedSlots: _vm._u([{
      key: "titAdd",
      fn: function () {
        return [_vm._v("03")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("아동‧청소년 관련기관등에의 취업제한")];
      },
      proxy: true
    }])
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 성범죄의 경우 형 또는 치료감호를 선고하는 판결을 받으면 아동‧청소년 관련기관등에 취업 또는 사실상 노무를 제공할 수 없도록 하는 취업제한명령을 판결과 동시에 필요적으로 선고해야 합니다. 특히 아동‧청소년 관련기관 중 의료법 제3조의 의료기관이 포함되어 있어서 의원, 치과의원, 한의원, 조산원, 병원, 치과병원, 한방병원, 요양병원, 정신병원, 종합병원 등 모든 의료기관이 포함되어 있어 의료기관 종사자는 더 큰 불이익을 받게 됩니다. ")]), _c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 단, 재범의 위험성이 현저히 낮은 경우, 그 밖의 취업을 제한하여서는 아니 되는 특별한 사정이 있다고 판단하는 경우에는 취업제한명령을 선고하지 않을 수 있으므로 반드시 변호사의 조력을 받는 것이 필요합니다. ")])]), _c('line-row', {
    scopedSlots: _vm._u([{
      key: "titAdd",
      fn: function () {
        return [_vm._v("04")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("전자장치 부착명령")];
      },
      proxy: true
    }])
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 성범죄를 범한 사람 중 재범의 위험성(① 형 집행 종료 후 10년 이내 동종재범, ② 전자장치 부착 전력자가 동종 재범, ③ 2회 이상 범행(습벽 인정), ④ 19세 미만자 대상 범죄, ⑤ 장애인 대상 범죄)이 인정되는 경우에 부수되는 보안처분 입니다. 일명“전자발찌”로 불리는 전자장치를 부착하는데 외부적으로 전자발찌가 노출되는 점, 야간외출, 주거지제한 등 생활제약이 크므로 형벌에 준하는 매우 큰 법률상 불이익이라고 할 수 있습니다. ")]), _c('div', {
    staticClass: "mt-12px mt-md-24px"
  }, [_c('table-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200",
      "topLine": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('table-th', {
    staticClass: "min-w-md-160px"
  }, [_vm._v(" 특정범죄 ")])], 1), _c('v-col', {
    staticClass: "mt-n8px mt-md-0",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('table-td', [_c('p', [_vm._v("성폭력, 미성년자 유괴, 살인, 강도")]), _c('p', {
    staticClass: "mt-4px mt-md-10px"
  }, [_vm._v("※ 가석방은 죄명 구분 없이 모든 대상자에게 적용(‘20.8.5. 시행)")])])], 1)], 1)], 1), _c('table-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('table-th', {
    staticClass: "min-w-md-160px"
  }, [_vm._v(" 청구요건 등 ")])], 1), _c('v-col', {
    staticClass: "mt-n8px mt-md-0",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('table-td', [_c('div', [_c('strong', [_vm._v("[성폭력, 강도]")]), _c('p', {
    staticClass: "mt-4px mt-md-10px"
  }, [_vm._v("※ 가석방은 죄명 구분 없이 모든 대상자에게 적용(‘20.8.5. 시행)")])]), _c('div', {
    staticClass: "mt-16px mt-md-32px"
  }, [_c('strong', [_vm._v("[미성년자 대상 유괴범죄, 살인범죄]")]), _c('p', {
    staticClass: "mt-4px mt-md-10px"
  }, [_vm._v("①동종 재범위험성, ②실형 전력자가 동종 재범(재범위험성 불요, 필요적 청구)")])]), _c('div', {
    staticClass: "mt-16px mt-md-32px"
  }, [_c('strong', [_vm._v("[부착기간]")]), _c('p', {
    staticClass: "mt-4px mt-md-10px"
  }, [_vm._v(" 법정형 상한이 사형 또는 무기징역 : 10년 ~ 30년 "), _c('br'), _vm._v(" 징역형 하한이 3년 이상의 유기징역 : 3년 ~ 20년 "), _c('br'), _vm._v(" 징역형 하한이 3년 미만의 유기징역 : 1년 ~ 10년 "), _c('br'), _vm._v(" ※ 단, 19세 미만자 대상 범행시 하한 2배 가중 ")]), _c('p', {
    staticClass: "mt-4px mt-md-10px"
  }, [_vm._v(" ※ 경합범에 대하여 가장 중한 죄의 부착기간 상한의 1/2까지 가중 가능(최장 45년) ")])])])], 1)], 1)], 1)], 1), _c('p', {
    staticClass: "line-height-13",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" [부착기간] "), _c('br'), _c('br'), _vm._v(" 법정형 상한이 사형 또는 무기징역 : 10년 ~ 30년 "), _c('br'), _vm._v(" 징역형 하한이 3년 이상의 유기징역 : 3년 ~ 20년 "), _c('br'), _vm._v(" 징역형 하한이 3년 미만의 유기징역 : 1년 ~ 10년 "), _c('br'), _vm._v(" ※ 단, 19세 미만자 대상 범행시 하한 2배 가중 ")]), _c('div', {
    staticClass: "mt-12px mt-md-24px text-center",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.penalty, function (item, index) {
    return _c('v-col', {
      key: index,
      class: index !== 0 ? 'mt-12px mt-md-24px mt-xl-0' : '',
      attrs: {
        "cols": "12",
        "xl": "6"
      }
    }, [_c('table-row', {
      attrs: {
        "topLine": ""
      }
    }, [_c('table-th', [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      class: index !== 0 ? 'border-xl-l' : ''
    }, _vm._l(item.txt, function (detail) {
      return _c('table-td', {
        key: detail,
        staticClass: "border-t min-h-md-80px d-flex align-center justify-center"
      }, [_c('span', {
        domProps: {
          "innerHTML": _vm._s(detail)
        }
      })]);
    }), 1)], 1)], 1);
  }), 1)], 1)])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }