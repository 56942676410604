<template>
    <div class="pa-10px px-md-20px py-md-16px">
        <p class="grey--text text--darken-4 font-weight-medium"><slot /></p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>