var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-10px px-md-20px py-md-16px"
  }, [_c('p', {
    staticClass: "grey--text text--darken-4 font-weight-medium"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }