var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "구성원 소개",
            "tabActive": "자문단 소개"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("A")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("dvisory committee")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._l(_vm.lawyers, function (lawyer, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('member-item', {
      attrs: {
        "to": `/member/${lawyer._id}`,
        "image": `${_vm.PANSIM}${lawyer.image}`
      },
      scopedSlots: _vm._u([{
        key: "txt",
        fn: function () {
          return [_vm._v(_vm._s(lawyer.job))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(lawyer.name) + " ")])], 1);
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }