<template>
    <app-primary v-if="currentBusiness.code">
        <template #contentsHead>
            <sub-head-primary itemTitle="담당업무" :tabActive="!$vuetify.breakpoint.mdAndUp ? currentBusiness.title : ''">
                <template #titHead>B</template>
                <template #tit>usiness</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <v-row class="row--xl">
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="auto">
                    <v-list class="v-list--side">
                        <v-list-item v-for="item in business" :key="item.title" :to="'/business/'+item.code">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="" class="business-contents">
                    <div class="overflow-hidden">
                        <div data-aos="zoom-out" class="image-card d-flex align-end h-120px h-md-240px mb-12px mb-md-24px"
                        :style="'background-image: url(/images/sub/business/'+currentBusiness.code+'/banner.jpg);'">
                            <v-img data-aos="fade-up" data-aos-delay="400" :src="'/images/sub/business/'+currentBusiness.code+'/text-bg.svg'" :aspect-ratio="980 / 138" class="w-100" />
                        </div>
                    </div>
                    <dialog-primary :btnAttrs="{ color: 'grey lighten-3' }" dark max-width="960">
                        <template #activator="{ attrs, on }">
                            <v-card v-bind="attrs" v-on="on" tile flat class="business-item plus-btn-card">
                                <div class="business-item__inner py-16px py-md-32px">
                                    <v-row align="center" class="row--lg">
                                        <v-col cols="12" md="auto">
                                            <v-row data-aos="fade-up" class="row--sm">
                                                <v-col cols="auto">
                                                    <icon-double-quotes class="primary--text text--lighten-3" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="w-md-140px">
                                                        <u-tit-default class="tit--sm font-primary">{{currentBusiness.title}}이란?</u-tit-default>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <u-txt-default data-aos="fade-up" data-aos-delay="200" class="line-height-16">
                                                <p v-html="currentBusiness.txt" class="white-nowrap"></p>
                                            </u-txt-default>
                                        </v-col>
                                        <v-col cols="12" md="auto">
                                            <div data-aos="fade-up" data-aos-delay="400">
                                                <icon-plus />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </template>

                        <template #dialogHead>
                            <div class="image-card w-100 d-flex flex-column align-center justify-center text-center pa-16px min-h-120px min-h-md-140px"
                            :style="'background-image: url(/images/sub/business/'+currentBusiness.code+'/dialog-banner.jpg);'">
                                <u-tit-default class="font-size-md-28 line-height-14">
                                    {{currentBusiness.title}}
                                </u-tit-default>
                                <u-txt-default class="grey--text text--lighten-3 line-height-17 mt-4px mt-md-8px">
                                    <p v-html="currentBusiness.txt"></p>
                                </u-txt-default>
                            </div>
                        </template>
                        <u-txt-default class="white--text">
                            <v-row>
                                <v-col v-for="(category, index) in currentBusiness.category" :key="index" cols="12">
                                    <v-row align="center" justify="center" no-gutters>
                                        <v-col cols="auto" class="mb-n40px mb-md-0 mr-md-n50px z-1" style="position: relative;">
                                            <div class="w-80px h-80px w-md-100px h-md-100px primary rounded-circle d-flex align-center justify-center pa-12px">
                                                <strong class="text-center">{{category.title}}</strong>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <card-primary color="grey darken-3" dark>
                                                <div class="pa-20px pt-60px pa-md-32px pl-md-74px">
                                                    <v-row class="row--sm line-height-17">
                                                        <v-col v-for="detail in category.info.txt" :key="detail" cols="12">
                                                            <p>
                                                                {{detail}}
                                                            </p>
                                                        </v-col>
                                                    </v-row>                                                    
                                                    <div v-if="category.info.caption" class="pt-8px pt-md-16px">
                                                        <card-primary color="rgba(232, 148, 163, .2)" dark>
                                                            <div class="pa-4px">
                                                                <v-row align="center" class="row--xxs">
                                                                    <v-col cols="auto">
                                                                        <icon-forward class="primary--text" />
                                                                    </v-col>
                                                                    <v-col>
                                                                        <p>
                                                                            {{category.info.caption}}
                                                                        </p>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                        </card-primary>
                                                    </div>
                                                </div>
                                            </card-primary>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </u-txt-default>
                    </dialog-primary>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import IconForward from "@/components/publish/styles/icons/icon-forward.vue";
import IconDoubleQuotes from "@/components/publish/styles/icons/icon-double-quotes.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import UTabsDot from "@/components/publish/styles/tabs/u-tabs-dot.vue";
import UTabDot from "@/components/publish/styles/tabs/u-tab-dot.vue";

export default {
    props: {
    },
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        IconForward,
        IconDoubleQuotes,
        IconPlus,
        DialogPrimary,
        CardPrimary,
        UTabsDot,
        UTabDot,
    },
    data() {
        return { 
            business: [
                {
                    title: "성폭행",
                    code: "sexual-assault",
                    txt: "성폭행이란 넓은 의미로 성관계를 이용한 신체적, 언어적, 심리적 폭력 등 모든 폭력행위로 <br class='d-none d-lg-block' />형법, 「성폭력범죄의 처벌 등에 관한 특례법」,「아동·청소년의 성보호에 관한 법률」 등에 의하여 처벌되는 범죄 행위를 말합니다.",
                    category: [
                        {
                            title: "강간",
                            info: {
                                txt: [
                                    "폭행 또는 협박으로 사람을 강간한 경우를 말합니다. 최근에는 폭행 또는 협박이 없더라도 상대방의 명확한 거절의사가 있음에도 성관계를 한 경우 강간으로 인정되는 사례가 증가하고 있습니다.",
                                ],
                                caption: "3년 이상의 유기징역에서 피해자와 방법, 수단에 따라 무기징역으로 처벌받을 수 있습니다.",
                            }
                        },
                        {
                            title: "유사강간",
                            info: {
                                txt: [
                                    "폭행 또는 협박으로 사람의 구강, 항문 등 신체 내부에 성기를 넣거나 성기, 항문에 손가락 등 신체의 일부 또 도구를 넣는 행위를 말합니다.",
                                ],
                                caption: "2년 이상의 유기징역에서 피해자와 방법, 수단에 따라 7년 이상의 유기징역으로 처벌받을 수 있습니다.",
                            }
                        },
                        {
                            title: "준강간",
                            info: {
                                txt: [
                                    "사람의 심실상실 또는 항거불능의 상태를 이용하여 강간한 경우를 말합니다.",
                                    "특히 상대방이 술에 취한 상태, 잠이 든 상태 등 의식이 불명확한 상태에서 동의 없이 성관계를 할 경우, 준강간이 성립할 수 있습니다. 평소 성관계를 가지는 연인 사이, 부부 사이라고 하더라도 동의가 없었다면 준강간을 인정하는 사례가 증가하고 있습니다.",
                                ],
                                caption: "3년 이상의 유기징역에서 피해자와 방법, 수단에 따라 7년 이상의 유기징역으로 처벌받을 수 있습니다.",
                            }
                        },
                    ],
                },
                {
                    title: "성추행",
                    code: "sexual-harassment",
                    txt: "성추행은 폭행, 협박으로 사람에 대하여 추행한 경우 성립하는 범죄입니다. <br class='d-none d-lg-block' />이때 폭행, 협박은 성폭행과 달리 항거를 곤란하게 할 정도이면 충분하고 폭행, 협박 없이 기습으로도 성추행 성립이 가능합니다.",
                    category: [
                        {
                            title: "강제추행",
                            info: {
                                txt: [
                                    "폭행 또는 협박으로 상대방의 의사에 반하여 신체 접촉행위를 하는 것을 의미합니다.",
                                    "최근에는 폭행이나 협박 없이 신체 접촉만으로 상대방이 성적수치심을 느꼈다면 강제추행으로 혐의가 인정되는 사례가 증가하고 있습니다. 하급심이긴 하나 최근에는 신체 접촉이 없는 경우에도  강제추행이 인정된 사례가 있습니다.",
                                ],
                            }
                        },
                        {
                            title: "준강제추행",
                            info: {
                                txt: [
                                    "사람의 심실상실 또는 항거불능의 상태를 이용하여 추행한 경우를 말합니다.",
                                    "특히 상대방이 술에 취한 상태, 잠이 든 상태 등 의식이 불명확한 상태에서 신체접촉을 할 경우, 준강제추행이 성립할 수 있습니다. 평소 성관계를 가지는 연인사이, 부부사이라고 하더라도 동의가 없었다면 준강제추행을 인정하는 사례가 증가하고 있습니다.",
                                ],
                            }
                        },
                        {
                            title: "공중밀집장소 에서의 추행",
                            info: {
                                txt: [
                                    "대중교통수단, 공연 집회 장소, 그 밖의 공중이 밀집하는 장소에서 사람을 추행한 것을 의미합니다.",
                                    "대표적으로 “공연장 성추행”, “버스 성추행”, “지하철 성추행”이 여기에 해당합니다. 지하철에서 하차하는 중에  신체접촉이 있는 경우 상대방이 접촉으로 인해 성적수치심을 느꼈다면 억울한 처벌을 받을 수 있습니다.",
                                ],
                            }
                        },
                    ],
                },
                {
                    title: "성매매",
                    code: "prostitution",
                    txt: "불특정인을 상대로 금품이나 그 밖의 재산상의 이익을 받거나 받기로 약속하고 <br class='d-none d-lg-block' />성교행위 또는 구강, 항문 등 신체의 일부 또는 도구를 이용한 유사 성교행위를 하는 것을 말합니다.",
                    category: [
                        {
                            title: "성매매",
                            info: {
                                txt: [
                                    "불특정인을 상대로 금품이나 그 밖의 재산상의 이익을 받거나 받기로 약속하고 성교행위 또는 구강, 항문 등 신체의 일부 또는 도구를 이용한 유사 성교행위를 하는 것을 말합니다.",
                                    "성매매는 현장에서 발각되지 않으면 처벌받지 않는다는 인식이 있습니다. 그러나 최근 수사기관에서 전방위적 수사를 개시할 경우에 성매매업체 장부에 기록된 연락처, 이름만으로도 수사의 대상이 되므로 반드시 초기단계 에서 변호사의 조력을 받아 대응해야 합니다.",
                                ],
                            }
                        },
                        {
                            title: "성매매 알선 등 행위",
                            info: {
                                txt: [
                                    "성매매를 알선, 권유, 유인 또는 강요하는 행위이며, 성매매의 장소를 제공하거나 자금을 제공한 행위, 성을사고 파는 행위를 한 경우를 말합니다.",
                                ],
                            }
                        },
                    ],
                },
            ],
            currentBusiness: {},
        }
    },     
    created() {
        this.updateCurrentBusiness();
    },
    watch: {
        $route(newRoute, oldRoute) {
            this.updateCurrentBusiness();
        },
    },
    methods: {
        updateCurrentBusiness() {
            const code = this.$route.params.code;
            this.currentBusiness = this.business.find(item => item.code === code) || {};
        },
    },
};
</script>

<style lang="scss" scoped>
.v-list{
    padding: 0;
    width: 160px;
}
.v-list-item{
    padding: 0;
    min-height: 60px;
    &::before,
    &::after{
        display: none;
    }
    &__content{
        flex-wrap: nowrap;
        &::after{
            content: "";   
            height: 12px;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            margin-left: 8px;
            background-image: url(/images/icon/list-active.svg);
            background-position: right 1px center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-out;
        }
    }
    &__title{
        font-size: 1.8rem;
        color: var(--v-grey-lighten1);
        transition: .3s ease-out;        
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    &--active{
        .v-list-item__content{
            &::after{
                opacity: 1;
                visibility: visible;
            }
        }
        .v-list-item__title{
            color: var(--v-primary-base);
            font-weight: 700;
        }
    }
}
.business-item{
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    padding: 0;
    transition: .3s ease-out;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/images/sub/business/business-active.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        visibility: hidden;
        transition: .3s ease-out;
    }
    &__inner{
        position: relative;
        z-index: 1;
        >.row--lg,
        >.row--lg>[class*=col]{
            transition: .3s ease-out;
        }
        ::v-deep{
            .icon-plus{
                color: var(--v-grey-base);
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){    
    .business-contents{
        max-width: calc(100% - 220px);
    }
    .white-nowrap{
        ::v-deep{
            >br.d-md-block{                
                display: none !important;
            }
        }
    }
}
@media (min-width:1024px){
    .v-list-item:hover{
        .v-list-item__title{
            color: var(--v-primary-base);
            font-weight: 700;
        }
    }
    .business-item:hover{
        --grid-gutter-lg: 8px;
        padding: 0 24px;
        border-color: #fff;
        &::before{
            opacity: 1;
            visibility: visible;
        }
        *:not(.icon-double-quotes){
            color: #fff;
        }
    }
    .white-nowrap{
        ::v-deep{
            >br.d-lg-block{                
                display: none !important;
            }
        }
    }
}
@media (min-width:1200px){
    .white-nowrap{
        ::v-deep{
            >br.d-xl-block{                
                display: none !important;
            }
        }    
    }
}

</style>
