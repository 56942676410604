var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    staticClass: "mb-40px mb-md-80px",
    attrs: {
      "data-aos": "fade-up"
    },
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": function () {
        _vm.page = 1;
        _vm.search();
      }
    }
  }), _c('v-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, _vm._l(_vm.insights, function (insight, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('card-primary', {
      staticClass: "media-item v-card--none-active-bg cursor-pointer plus-btn-card",
      on: {
        "click": function ($event) {
          return _vm.open(insight.youtube.id);
        }
      }
    }, [_c('v-responsive', {
      staticClass: "media-item__thumb image-card w-100",
      style: `background-image: url('${_vm.PANSIM}${insight.thumb}')`,
      attrs: {
        "aspect-ratio": 464 / 260
      }
    }), _c('div', {
      staticClass: "pt-16px pt-md-36px pb-10px pb-md-20px"
    }, [_c('u-txt-default', {
      staticClass: "txt--light line-height-14 pb-8px pb-md-16px"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_vm._v(" " + _vm._s(insight.label) + " ")]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(insight.createdAt).format("YYYY-MM-DD")) + " ")])], 1)], 1), _c('u-tit-default', {
      staticClass: "tit--sm line-height-15 font-weight-medium text-truncate-2"
    }, [_vm._v(" " + _vm._s(insight.subject) + " ")]), _c('icon-plus', {
      staticClass: "grey--text mt-24px mt-md-48px",
      attrs: {
        "size": "small"
      }
    })], 1)], 1)], 1);
  }), 1), _c('v-bottom-sheet', {
    attrs: {
      "eager": "",
      "fullscreen": "",
      "content-class": "mw-100 h-100",
      "max-width": "100%",
      "width": "100%"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "z-index": "10"
    },
    attrs: {
      "x-large": "",
      "absolute": "",
      "top": "",
      "right": "",
      "icon": "",
      "text": "",
      "tile": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('icon-close')], 1), _c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "aspect-ratio": 1440 / 810
    }
  }, [_c('iframe', {
    staticClass: "w-100 h-100 d-block",
    attrs: {
      "src": 'https://www.youtube.com/embed/' + _vm.selectedVideo,
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }