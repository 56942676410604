<template>
    <u-txt-default>
        <v-divider v-if="topLine" class="border-1 grey darken-4" />
        <slot />
        <v-divider />
    </u-txt-default>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        topLine: { type: Boolean, default: false },
    },
    components: {
        UTxtDefault,
    }
}
</script>

<style>

</style>