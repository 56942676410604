<template>
    <div>
        <board-filter data-aos="fade-up" class="mb-40px mb-md-80px"         
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="()=> {page=1; search()}"/>

        <v-row data-aos="fade-up" data-aos-delay="200">

            <!-- S: media -->
            <v-col cols="6" md="4"  v-for="(insight, index) in insights" :key="index">
                <router-link :to="`/insight_media/review/${insight._id}`" class="media-item plus-btn-card">
                    <v-responsive :aspect-ratio="1 / 1" class="media-item__thumb image-card w-100" :style="`background-image: url('${PANSIM}${insight.thumb}')`"  />
                    <div class="pt-16px pt-md-36px pb-10px pb-md-20px">
                        <u-txt-default class="txt--light line-height-14 pb-8px pb-md-16px">
                            <v-row no-gutters>
                                <v-col>
                                    {{ insight.label }}
                                </v-col>
                                <v-col cols="auto">
                                    {{ $dayjs(insight.createdAt).format("YYYY-MM-DD")}}
                                </v-col>
                            </v-row>
                        </u-txt-default>
                        <u-tit-default class="tit--sm line-height-15 font-weight-medium text-truncate-2">
                            {{insight.subject}}
                        </u-tit-default>
                        <icon-plus size="small" class="grey--text mt-24px mt-md-48px" />
                    </div>
                </router-link>
            </v-col>
            <!-- E: media -->            

        </v-row>

    </div>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from "@/assets/variables"
import BoardFilter from "@/components/client/board/board-filter.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {
    },
    components: {
        BoardFilter,
        IconPlus,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    data() {
        return {
            PANSIM: process.env.VUE_APP_PASIM_URL,
            insights: [],
            filter: { 
                code: "review",
                site: PANSIM_TYPES.SEX_OFFENCE.value,
                searchKey: "subject"
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            this.$router.push({
                query: Object.assign({}, this.query ),
            });


            try{
                let { summary, insights} = await api.v1.insights.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter
                })
                
                this.insights = insights;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

    },
}
</script>

<style lang="scss" scoped>
.media-item{
    display: block;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .media-item{
        display: block;
        &__thumb{
            position: relative;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(23, 28, 33, .4);
                opacity: 0;
                visibility: hidden;
                transition: .3s ease-out;
            }
        }
        &:hover{
            .media-item__thumb{
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

</style>