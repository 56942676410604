<template>
    <div>
        <board-filter data-aos="fade-up" class="mb-40px mb-md-80px"         
        @searchKey="(searchKey) => { filter.searchKey = searchKey }"
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="()=> {page=1; search(true)}"/>

        <v-row data-aos="fade-up" data-aos-delay="200">

            <!-- S: story -->
            <v-col v-for="(success, index) in successes" :key="index" cols="6" md="4">
                <story-item :to="`/story/${success._id}`" :thumb="success.thumb">
                    {{ success.subject}}
                    <template #date>{{ $dayjs(success.createdAt).format("YYYY-MM-DD") }}</template>
                </story-item>
            </v-col>
            <!-- E: story -->            

        </v-row>

    </div>
</template>

<script>
import api from "@/api";
import { PANSIM_TYPES } from "@/assets/variables"
import BoardFilter from "@/components/client/board/board-filter.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import StoryItem from "@/components/client/board/skin/story/item.vue";

export default {
    props: {
        category: { type: String, default: ""}
    },
    components: {
        BoardFilter,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,

        StoryItem,
    },
    data() {
        return {
            successes: [],
            filter: { 
                code: this.category || this.$route.query.code || null,
                site: PANSIM_TYPES.SEX_OFFENCE.value,
                searchKey: 'subject'
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(routable = false){
            if (routable) {
                this.$router.push({
                    query: Object.assign({}, {...this.filter} ),
                });
            }

            try{
                let { summary, successes } = await api.v1.success.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter
                })
                
                this.successes = successes;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        "$route.query.code"(){
            this.filter.code = this.$route.query.code;
            this.search();
        }
    }
}
</script>