<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="성범죄 보안처분">
                <template #titHead>S</template>
                <template #tit>ecurity disposition</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <div class="overflow-hidden">
                <v-img data-aos="zoom-out" src="/images/sub/disposition/disposition-img.jpg" :aspect-ratio="1200 / 240" class="min-h-md-240px mb-12px mb-md-24px">
                    <div class="w-100 h-100 d-flex flex-column justify-center align-center text-center pa-20px pa-md-30px pa-lg-48px">
                        <icon-double-quotes data-aos="fade-up" data-aos-delay="400" data-aos-offset="0" class="primary--text text--lighten-3 mb-8px mb-md-16px" />
                        <u-tit-default data-aos="fade-up" data-aos-delay="600" data-aos-offset="0" class="white--text line-height-1">성범죄 보안처분이란?</u-tit-default>
                        <u-txt-default data-aos="fade-up" data-aos-delay="800" data-aos-offset="0" class="grey--text text--lighten-3 line-height-17 mt-4px mt-md-10px">
                            유죄가 확정되어 벌금형, 징역형 등의 처벌을 받는 것 외에 부수적으로 받게 되는 법률상 불이익을 말합니다.
                        </u-txt-default>
                    </div>
                </v-img>
            </div>
            <div>
                <line-row topLine>
                    <template #titAdd>01</template>
                    <template #tit>신상정보등록</template>
                    <p data-aos="fade-up" data-aos-delay="200"> 
                        성범죄로 벌금 이상의 유죄판결이나 약식명령이 확정된 경우에 부수되는 보안처분입니다. <br />
                        (아동청소년이용음란물소지죄, 성적목적공공장소침입죄, 카메등이용촬영죄, 통신매체이용 음란죄 제외). 대부분의
                        성범죄에서 벌금만 내도 신상정보등록이 부과되므로 다른 기타범죄와 성범죄가 차별되는 부분이기도 합니다.
                    </p>
                    <card-secondary class="mt-12px mt-md-24px">
                        <div class="pa-16px px-md-36px py-md-24px">
                            <v-row class="row--sm">
                                <v-col data-aos="fade-up" :data-aos-delay="200 + (index*100)" v-for="(item, index) in prison" :key="index" cols="6" lg="3">
                                    <v-responsive :aspect-ratio="1 / 1" class="image-card w-100" content-class="pa-16px d-flex flex-column align-center justify-center text-center"
                                    style="background-image: url(/images/icon/rhomb.svg)">
                                        <u-txt-default class="txt--lg font-weight-bold line-height-1 txt--dark">{{item.title}}</u-txt-default>
                                        <p class="grey--text line-height-13 mt-4px mt-md-8px">{{item.txt}}</p>
                                    </v-responsive>
                                </v-col>
                            </v-row>
                        </div>
                    </card-secondary>
                    <p data-aos="fade-up" data-aos-delay="200"> 
                        등록대상자는 기본신상정보를 유죄판결 확정일로부터 30일 이내에 주소지 또는 거주지를 관할하는 경찰관서의 장에게
                        직접 방문하여 신상정보 제출해야 합니다(다만, 교정시설에 수용된 경우에는 수용시설의 장에게 제출) 또한 신상정보를
                        제출한 그 다음 해부터 매년 12월 31일까지 주소지 관할 경찰관서에 출석하여 사진 촬영을 해야 합니다.
                    </p>
                    <p data-aos="fade-up" data-aos-delay="200"> 
                        신상정보에 변경이 있다면 변경 사유가 발생한 날로부터 20일 이내에 변경 내용을 관할 경찰관서의 장에게 제출해야
                        하고 해외출입국 시 6개월 이상 국외에 체류하기 위하여 출국하는 경우 관할 경찰관서의 장에게 출국 신고,
                        입국일로부터 14일 이내 관할 경찰관서의 장에게 입국 신고를 해야 합니다.
                    </p>
                </line-row>
                <line-row>
                    <template #titAdd>02</template>
                    <template #tit>신상정보공개, 고지</template>
                    <p data-aos="fade-up" data-aos-delay="200">
                        성범죄자 신상정보 공개, 고지 제도는 등록대상 성범죄 사건의 판결과 동시에 공개 및 고지 명령을 선고하고, 법무부에서 신상정보를 등록한 후 공개 및 고지에 필요한 정보를 여성가족부로 전송, 여성가족부가 공개와 고지명령을 집행합니다.
                    </p>
                    <div class="pt-12px pt-md-24px">
                        <v-row>
                            <v-col data-aos="fade-up" :data-aos-delay="200 + (index*100)" v-for="(item, index) in notice" :key="index" cols="12" md="6">
                                <card-primary color="grey lighten-5" class="h-100">
                                    <div class="pa-16px pa-md-24px">
                                        <u-txt-default class="txt--lg font-weight-bold txt--dark">{{item.title}}</u-txt-default>
                                        <u-txt-default class="txt--sm line-height-15 mt-12px mt-md-24px">
                                            {{item.txt}}
                                        </u-txt-default>
                                    </div>
                                </card-primary>
                            </v-col>
                        </v-row>
                    </div>
                </line-row>
                <line-row>
                    <template #titAdd>03</template>
                    <template #tit>아동‧청소년 관련기관등에의 취업제한</template>
                    <p data-aos="fade-up" data-aos-delay="200"> 
                        성범죄의 경우 형 또는 치료감호를 선고하는 판결을 받으면 아동‧청소년 관련기관등에 취업 또는 사실상 노무를 제공할 수 없도록 하는 취업제한명령을 판결과 동시에 필요적으로 선고해야 합니다. 특히 아동‧청소년 관련기관 중 의료법
                        제3조의 의료기관이 포함되어 있어서 의원, 치과의원, 한의원, 조산원, 병원, 치과병원, 한방병원, 요양병원, 정신병원,
                        종합병원 등 모든 의료기관이 포함되어 있어 의료기관 종사자는 더 큰 불이익을 받게 됩니다.
                    </p>
                    <p data-aos="fade-up" data-aos-delay="200"> 
                        단, 재범의 위험성이 현저히 낮은 경우, 그 밖의 취업을 제한하여서는 아니 되는 특별한 사정이 있다고 판단하는 
                        경우에는 취업제한명령을 선고하지 않을 수 있으므로 반드시 변호사의 조력을 받는 것이 필요합니다.
                    </p>
                </line-row>
                <line-row>
                    <template #titAdd>04</template>
                    <template #tit>전자장치 부착명령</template>
                    <p data-aos="fade-up" data-aos-delay="200">
                        성범죄를 범한 사람 중 재범의 위험성(① 형 집행 종료 후 10년 이내 동종재범, ② 전자장치 부착 전력자가 동종 재범,
                        ③ 2회 이상 범행(습벽 인정), ④ 19세 미만자 대상 범죄, ⑤ 장애인 대상 범죄)이 인정되는 경우에 부수되는 보안처분
                        입니다. 일명“전자발찌”로 불리는 전자장치를 부착하는데 외부적으로 전자발찌가 노출되는 점, 야간외출, 주거지제한
                        등 생활제약이 크므로 형벌에 준하는 매우 큰 법률상 불이익이라고 할 수 있습니다.
                    </p>
                    <div class="mt-12px mt-md-24px">
                        <table-row data-aos="fade-up" data-aos-delay="200" topLine>
                            <v-row no-gutters align="center">
                                <v-col cols="12" md="auto">
                                    <table-th class="min-w-md-160px">
                                        특정범죄
                                    </table-th>
                                </v-col>
                                <v-col cols="12" md="" class="mt-n8px mt-md-0">
                                    <table-td>
                                        <p>성폭력, 미성년자 유괴, 살인, 강도</p>
                                        <p class="mt-4px mt-md-10px">※ 가석방은 죄명 구분 없이 모든 대상자에게 적용(‘20.8.5. 시행)</p>
                                    </table-td>
                                </v-col>
                            </v-row>
                        </table-row>
                        <table-row data-aos="fade-up" data-aos-delay="200">
                            <v-row no-gutters align="center">
                                <v-col cols="12" md="auto">
                                    <table-th class="min-w-md-160px">
                                        청구요건 등
                                    </table-th>
                                </v-col>
                                <v-col cols="12" md="" class="mt-n8px mt-md-0">
                                    <table-td>
                                        <div>
                                            <strong>[성폭력, 강도]</strong>
                                            <p class="mt-4px mt-md-10px">※ 가석방은 죄명 구분 없이 모든 대상자에게 적용(‘20.8.5. 시행)</p>
                                        </div>
                                        <div class="mt-16px mt-md-32px">
                                            <strong>[미성년자 대상 유괴범죄, 살인범죄]</strong>
                                            <p class="mt-4px mt-md-10px">①동종 재범위험성, ②실형 전력자가 동종 재범(재범위험성 불요, 필요적 청구)</p>
                                        </div>
                                        <div class="mt-16px mt-md-32px">
                                            <strong>[부착기간]</strong>
                                            <p class="mt-4px mt-md-10px">
                                                법정형 상한이 사형 또는 무기징역 : 10년 ~ 30년 <br />
                                                징역형 하한이 3년 이상의 유기징역 : 3년 ~ 20년 <br />
                                                징역형 하한이 3년 미만의 유기징역 : 1년 ~ 10년 <br />
                                                ※ 단, 19세 미만자 대상 범행시 하한 2배 가중
                                            </p>
                                            <p class="mt-4px mt-md-10px">
                                                ※ 경합범에 대하여 가장 중한 죄의 부착기간 상한의 1/2까지 가중 가능(최장 45년)
                                            </p>
                                        </div>
                                    </table-td>
                                </v-col>
                            </v-row>
                        </table-row>
                    </div>
                    <p data-aos="fade-up" data-aos-delay="200" class="line-height-13">
                        [부착기간] <br /><br />

                        법정형 상한이 사형 또는 무기징역 : 10년 ~ 30년 <br />
                        징역형 하한이 3년 이상의 유기징역 : 3년 ~ 20년 <br />
                        징역형 하한이 3년 미만의 유기징역 : 1년 ~ 10년 <br />
                        ※ 단, 19세 미만자 대상 범행시 하한 2배 가중
                    </p>
                    <div data-aos="fade-up" data-aos-delay="200" class="mt-12px mt-md-24px text-center">
                        <v-row no-gutters>
                            <v-col v-for="(item, index) in penalty" :key="index" cols="12" xl="6" :class="index !== 0 ? 'mt-12px mt-md-24px mt-xl-0' : ''">
                                <table-row topLine>
                                    <table-th>
                                        {{item.title}}
                                    </table-th>
                                    <div :class="index !== 0 ? 'border-xl-l' : ''">
                                        <table-td v-for="detail in item.txt" :key="detail" class="border-t min-h-md-80px d-flex align-center justify-center">
                                            <span v-html="detail"></span>
                                        </table-td>
                                    </div>
                                </table-row>
                            </v-col>
                        </v-row>
                    </div>
                </line-row>
            </div>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import LineRow from "@/sets/styles/pages/line-row.vue";
import TableRow from "@/sets/styles/pages/table-row.vue";
import TableTh from "@/sets/styles/pages/table-th.vue";
import TableTd from "@/sets/styles/pages/table-td.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import IconDoubleQuotes from "@/components/publish/styles/icons/icon-double-quotes.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        LineRow,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        IconDoubleQuotes,
        CardPrimary,
        CardSecondary,
        TableRow,
        TableTh,
        TableTd,
    },
    data() {
        return {
            prison: [
                {
                    title: "10년",
                    txt: "벌금형",
                },
                {
                    title: "15년",
                    txt: "징역 3년 이하",
                },
                {
                    title: "20년",
                    txt: "징역 10년 이하",
                },
                {
                    title: "30년",
                    txt: "징역 10년 초과",
                },
            ],
            notice: [
                {
                    title: "신상정보 공개",
                    txt: "공개대상자의 성명, 나이, 사진, 주소 및 실제거주지, 키, 몸무게, 성범죄요지, 성폭력범죄 전과사실(죄명 및 횟수), 전자장치 부착여부 등을 인터넷 공개 전용 누리집(성범죄자 알림e)과 모바일 앱을 통해 공개됩니다.",
                },
                {
                    title: "신상정보 고지",
                    txt: "공개대상자의 성명, 나이, 사진, 주소 및 실제거주지, 키, 몸무게, 성범죄요지, 성폭력범죄 전과사실(죄명 및 횟수), 전자장치 부착여부 등을 성범죄자가 거주하는 읍‧면‧동 내  아동‧청소년 보호 세대주에게는 모바일고지, 세대주 및 기관(어린이집, 유치원, 학원, 학교, 지역아동센터, 청소년 수련시설)에는 우편고지를 하게 됩니다 (모바일고지 미열람 시 우편으로 고지됨).",
                },
            ],
            penalty: [
                {
                    title: "전자장치부착법14조(의무)",
                    txt: [
                        "야간 등 특정 시간대 외출 제한",
                        "특정지역장소 출입금지",
                        "주거지역 제한",
                        "피해자 등 특정인 접근금지",
                        "특정범죄 치료 프로그램 이수",
                        "마약 등 중독성 있는 물질 사용금지",
                        "그 밖에 부착명령을 선고받은 사람의 <br class='d-none d-xl-block' />재범 방지와 성행교정을 위하여 필요한 사항",
                    ],
                },
                {
                    title: "위반 시 벌칙",
                    txt: [
                        "외출 제한 위반 <br />1년 이하 징역 또는 1천만 원 이하 벌금",
                        "출입 금지 위반 <br />1년 이하 징역 또는 1천만 원 이하 벌금",
                        "주거지 제한 위반 <br />1년 이하 징역 또는 1천만 원 이하 벌금",
                        "접근 금지 위반 <br />3년 이하 징역 또는 3천만 원 이하 벌금",
                        "특정범죄 치료 프로그램 이수 위반 <br />3년 이하 징역 또는 3천만 원 이하 벌금",
                        "마약 등 사용금지 위반 <br />1년 이하 징역 또는 1천만 원 이하 벌금",
                        "그 밖에 재범 방지와 성행교정을 위하여 필요한 사항 위반 <br class='d-none d-xl-block' />1년 이하 징역 또는 1천만 원 이하 벌금",
                    ],
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
