<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="언론&미디어" tabActive="의뢰인 후기">
                <template #titHead>I</template>
                <template #tit>nsight & <span data-aos="zoom-out" data-aos-delay="1000" class="d-inline-block primary--text">M</span>edia</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <review-list/>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

import ReviewList from "@/components/client/board/skin/review/list.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,

        ReviewList,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
