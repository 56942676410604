<template>
    <th v-bind="$attrs" class="primary--text">
        <slot />
    </th>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
