var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "변변변성범죄센터",
            "tabActive": "변변변 성범죄 센터 소개"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("T")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("he Firm")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--about page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-img', {
          staticClass: "page-section--about__bg",
          attrs: {
            "data-aos": "zoom-out",
            "src": "/images/sub/firm/about/about-bg.svg",
            "aspect-ratio": 704 / 486
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('u-tit-default', {
    staticClass: "line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 판심의 성범죄사건 대응 전략. "), _c('br'), _vm._v(" 따라할 수도, 비교할 수도 없습니다. ")]), _c('u-txt-default', {
    staticClass: "txt--xxl line-height-15 mt-12px mt-md-24px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 판사의 마음, 판심만의 "), _c('br'), _vm._v(" 원하는 판결을 받기 위한 고도의 소송전략은? ")])], 1), _c('v-col', {
    staticClass: "mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pl-2px"
  }, [_c('v-row', _vm._l(_vm.info, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200",
        "cols": "12"
      }
    }, [_c('card-secondary', [_c('div', {
      staticClass: "pa-16px pa-md-24px"
    }, [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.icon,
        "width": _vm.$vuetify.breakpoint.mdAndUp ? 120 : 100,
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-primary font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('u-txt-default', {
      staticClass: "line-height-15 mt-10px"
    }, [_vm._v(" " + _vm._s(item.txt) + " ")])], 1)], 1)], 1)])], 1);
  }), 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }