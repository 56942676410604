var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.topLine ? _c('v-divider', {
    staticClass: "grey lighten-3"
  }) : _vm._e(), _c('div', {
    staticClass: "py-20px py-md-30px py-lg-48px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [this.$slots['titAdd'] ? _c('u-tit-default', {
    staticClass: "tit--sm font-size-md-28 font-weight-semibold primary--text line-height-1 mb-8px mb-md-16px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("titAdd")], 2) : _vm._e(), _c('u-tit-default', {
    staticClass: "tit--sm",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("tit")], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "pl-md-56px"
  }, [_c('u-txt-default', {
    staticClass: "line-height-17"
  }, [_vm._t("default")], 2)], 1)])], 1)], 1), _c('v-divider', {
    staticClass: "grey lighten-3"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }