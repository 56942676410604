<template>
    <div>
        <v-divider v-if="topLine" class="grey lighten-3" />
        <div class="py-20px py-md-30px py-lg-48px">
            <v-row>
                <v-col cols="12" md="4">
                    <u-tit-default data-aos="fade-up" v-if="this.$slots['titAdd']" class="tit--sm font-size-md-28 font-weight-semibold primary--text line-height-1 mb-8px mb-md-16px">
                        <slot name="titAdd" />
                    </u-tit-default>
                    <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm">
                        <slot name="tit" />
                    </u-tit-default>
                </v-col>
                <v-col cols="12" md="8">
                    <div class="pl-md-56px">
                        <u-txt-default class="line-height-17" >
                            <slot />
                        </u-txt-default>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider class="grey lighten-3" />
    </div>
</template>

<script>
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {
        topLine: { type: Boolean, default: false },
    },
    components: {
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
}
</script>

<style>

</style>