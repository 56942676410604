var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('logo-primary', {
    attrs: {
      "to": "/guide",
      "title": "가이드"
    }
  }, [_vm._v("판심 법무법인 - 변변변성범죄센터")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('logo-secondary', [_vm._v("판심 법무법인 - 변변변성범죄센터")])], 1), _c('v-col', {
    staticClass: "grey",
    attrs: {
      "cols": "auto"
    }
  }, [_c('logo-tertiary', [_vm._v("판심 법무법인 - 변변변성범죄센터")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('logo-quarternary', [_vm._v("판심 법무법인 - 변변변성범죄센터")])], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(1), _c('br'), _c('v-row', [_c('v-col', {
    staticClass: "grey",
    attrs: {
      "cols": "auto"
    }
  }, [_c('logo', {
    staticClass: "logo--light"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('logo', {
    staticClass: "logo--dark"
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("logo-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("logo--{light|dark}")])]);

}]

export { render, staticRenderFns }