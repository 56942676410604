var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "사건별 맞춤 서비스"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("S")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("ervice")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "image-card pa-20px pa-md-30px px-lg-0 py-lg-70px",
    staticStyle: {
      "background-image": "url(/images/sub/service/service-bg.jpg)"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pl-lg-48px"
  }, [_c('u-tit-default', {
    staticClass: "line-height-17",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 판심 성범죄센터만의 사건별 맞춤서비스 ")]), _c('u-txt-default', {
    staticClass: "line-height-17 mt-16px mt-md-32px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 판심 법무법인 성범죄센터는 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 대표변호사를 포함한 5인의 변호인단이 의뢰인을 위한 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 사건별 최적의 맞춤 법률서비스를 제공합니다. ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "offset": "6",
      "offset-md": "0"
    }
  }, [_c('div', {
    staticClass: "w-100 pr-lg-40px"
  }, [_c('v-img', {
    staticClass: "w-100 ml-md-auto",
    attrs: {
      "data-aos": "zoom-out",
      "src": "/images/sub/service/service-bg2.svg",
      "max-width": "516",
      "aspect-ratio": 516 / 358
    }
  })], 1)])], 1)], 1), _vm._l(_vm.info, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "pt-40px pt-md-60px pt-lg-100px"
    }, [_c('v-row', {
      staticClass: "image-row",
      class: index % 2 == 0 ? 'image-row--reverse' : '',
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "image-row__image"
    }, [_c('div', {
      staticClass: "image-row__image__inner"
    }, [_c('div', {
      staticClass: "image-row__image__bg",
      attrs: {
        "data-aos": "zoom-out"
      }
    }), _c('v-img', {
      staticClass: "w-100",
      attrs: {
        "data-aos": "zoom-out",
        "data-aos-delay": "200",
        "src": item.image,
        "aspect-ratio": 1 / 1
      }
    })], 1)])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "image-row__txt"
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-semibold line-height-1 primary--text mb-8px mb-md-16px",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(" 0" + _vm._s(index + 1) + " ")]), _c('u-tit-default', {
      staticClass: "tit--sm",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('u-txt-default', {
      staticClass: "mt-30px mt-md-60px line-height-17"
    }, _vm._l(item.txt, function (detail) {
      return _c('p', {
        key: detail,
        attrs: {
          "data-aos": "fade-up",
          "data-aos-delay": "200"
        },
        domProps: {
          "innerHTML": _vm._s(detail)
        }
      });
    }), 0)], 1)])], 1)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }