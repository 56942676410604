var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-txt-default', [_vm.topLine ? _c('v-divider', {
    staticClass: "border-1 grey darken-4"
  }) : _vm._e(), _vm._t("default"), _c('v-divider')], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }